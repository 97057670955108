
.g-button{
    margin-top: 20px;
    /* text-justify: center; */
    /* background-color: aqua; */
}
.divider{
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: rgb(165, 158, 158);
}

body {
    background: #F7F8FC;
}

.login-card .card {
    background: transparent;
    border: 0;
}
.login-card .card h1 {
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 29px;
    color: #292929;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
}

.login-card .card .form-control {
    border: 1px solid #C7C7C7;
    box-sizing: border-box;
    filter: drop-shadow(8px 4px 20px rgba(97, 156, 144, 0.2));
    border-radius: 20px;
    background: transparent;
    height: 46px;
    
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;

    /* Black */

    color: #292929;
    padding-left: 24px;
    padding-right: 24px;

}

.login-card .card  .btn-primary {
    background: linear-gradient(106.56deg, #65D7C0 8.96%, #17A588 107.68%);
    box-shadow: 0px 4px 20px rgba(34, 172, 144, 0.2);
    border-radius: 20px;
    border: 0;
    width: 188px;
    height: 46px;
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    color: #FFFFFF;
    margin-top: 10px;
}

.forgot-password .btn-link{
    color: #292929;  
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    margin-top: 16px;
}

.login-card .divider{
    background-color: rgb(235 235 235);
}

.login-card .card-body {
    width: 454px;
    margin: 0 auto;
}
.login-card {
    position: relative;
}
.login-card:before {
    content: "";
    background: url(../../../assets/img/dotted-pattern.png) no-repeat;
    height: 307px;
    width: 260px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

header{ width: 100%;
    background: linear-gradient(106.56deg, #65D7C0 8.96%, #17A588 107.68%);
    padding:26px 0px;
    margin-bottom: 50px;
}

.navbar-nav li .nav-link {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}