// Your variable overrides
// $body-bg: #000;
// $body-color: #111;
$theme-colors: (
  "primary": #09898d,
);

// CoreUI and its default variables
@import "../../node_modules/@coreui/coreui/scss/coreui";

@font-face {
  font-family: "Gotham Pro";
  src: url("../../src/assets/fonts/GothamPro-Bold.eot");
  src: local("Gotham Pro Bold "), local("GothamPro-Bold"),
    url("../../src/assets/fonts/GothamPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../src/assets/fonts/GothamPro-Bold.woff") format("woff"),
    url("../../src/assets/fonts/GothamPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../src/assets/fonts/GothamPro.eot");
  src: local("Gotham Pro Italic"), local("GothamPro-Italic"),
    url("../../src/assets/fonts/GothamPro.eot?#iefix")
      format("embedded-opentype"),
    url("../../src/assets/fonts/GothamPro.woff") format("woff"),
    url("../../src/assets/fonts/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../src/assets/fonts/GothamPro-Medium.eot");
  src: local("Gotham Pro Medium"), local("GothamPro-Medium"),
    url("../../src/assets/fonts/GothamPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../src/assets/fonts/GothamPro-Medium.woff") format("woff"),
    url("../../src/assets/fonts/GothamPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
